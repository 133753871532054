<template>
  <v-main>
      <v-container>
        <ComponentTitle title="Account activation"/>
          <EmailCheckComponent />
      </v-container>
  </v-main>
</template>

<script>
import EmailCheckComponent from '../components/Registration/EmailCheckComponent.vue'
import ComponentTitle from '../components/UI/ComponentTitle.vue'
export default {
    components: {
        EmailCheckComponent,
        ComponentTitle
    }

}
</script>

<style>

</style>